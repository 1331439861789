.callBtn {
  border: none;
  font-size: 1.5rem;
  transition: all 0.3s ease-in-out;
  color: gray;
  background: transparent;
  cursor: pointer;
  padding: 7px;
  border-radius: 5px;
  position: relative;
  margin: 0 auto;
  left: -12px;
}
.player-top {
  width: 100%;
  display: flex;
}
.endCall:hover,
.endCall:focus,
.endCall:active {
  background: red !important;
}
.callBtn:hover,
.callBtn:focus,
.callBtn:active {
  filter: brightness();
  outline: none;
  background: #fff;
  box-shadow: none;
}
.callBtn:hover span {
  border: none;
}
.videoBtn {
  border: 1px solid #fff;
  border-radius: 50%;
}

svg:hover {
  color: #1b2027;
}
.callBtn:hover svg {
  color: #1b2027;
}
.callBtn:focus svg {
  color: #1b2027;
}
.callBtn:active svg {
  color: #1b2027;
}
.fa-stack > i {
  padding: unset;
  color: #fff;
}

.client-video {
  min-height: 20rem;
  text-align: center;
  margin-bottom:0em;
}

.participant {
  background: #1b2027;
  padding: 10px;
  border-radius: 6px;
  margin-right: 10px;
  transform: scaleX(-1) !important
}

video {
  width: 100%;
  display: block;
  margin: 0 auto;
  border-radius: 6px;
  transform: scaleX(-1);
  object-fit: cover;
}
.patient-video .room {
  margin: 2% 20%;
}
.room {
  background: #1b2028;
  min-height: 85vh;
}

.loader {
  width: 30rem !important;
  margin: -5rem auto !important;
}

.patient-video .self-video {
  right: 25rem;
  top: 5rem;
}
.patient-video .connection-sts {
  margin-left: 40%;
}
.patient-video .network-sts {
  margin: -2%;
}
.patient-video .network-indicator {
  width: 29.5% !important;
  margin: -2% 0 0 !important;
}
.self-video {
  position: absolute;
  top: 2rem;
  max-width: 15em;
  right: 1rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0 2em 2em;
}

.fa-rotate-45 {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.local video {
  width: 15rem !important;
}
.twilio-toolbar {
  /* margin: -6em auto;
  padding: 1%;
  position: relative;
  transition: all 0.5s ease-out; */
  position: absolute;
  left: calc(4.5vw - 40px);
  top: 50%;
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  z-index: 999;
  border-radius: 10px;
  background: #16171a;
  box-shadow: 9px 9px 16px #0a0b0c, -9px -9px 16px #222328;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* grid-gap: 0.2rem; */
  width: 40px;
}

/* .doctor-toolbar .twilio-toolbar {
  margin: 10rem auto;
  width: 20rem;
} */
.preload {
  background: #1b2028;
  min-height: 60vh;
}
.patient-video .preload {
  max-width: 47rem;
  margin-left: 16rem;
}
.preload-text {
  color: white;
  text-align: center;
  padding-top: 30vh;
}

.audio-only i {
  color: black;
}
.audio-only .videoBtn {
  border: none;
}
.connection-sts {
  color: #f969a4;
}
.network-sts {
  color: #f969a4;
  padding-right: 15px;
  margin: 0;
}
.connection-string {
  width: 50%;
}
.network-indicator {
  width: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  float: right;
  /* margin: -5% 0 0; */
}
.network-bar {
  width: 5px;
  border: 1px solid black;
}
.connection {
  font-weight: bold;
  color: green;
}

.Reconnecting {
  color: #f8bb86;
}
.Disconnected {
  color: red;
}

.local-participants .participant video {
  min-height: 28rem;
  min-width: 600px;
}
.participant video {
  height: 90vh;
}
.patient-video .not-connected {
  top: 5rem !important;
  right: 18rem !important;
}
.not-connected {
  top: 3rem !important;
  right: 7rem !important;
}
.not-connected .patient-status {
  top: 26rem !important;
}
/* .not-connected .twilio-toolbar {
  margin: 20rem auto !important;
} */
.video-header {
  height: 5%;
}
.patient-video .participant-status {
  position: absolute;
  color: white;
  top: 60rem;
  width: 100%;
  text-align: center;
  right: 5rem;
}
.participant-status {
  position: absolute;
  color: white;
  top: 54%;
  width: 90%;
  text-align: center;
}
.hidden {
  display: none;
}
.patient-status {
  top: 22rem;
  width: 90%;
  position: absolute;
  text-align: center;
  color: #ddd;
}
.patient-video .patient-status {
  top: 18rem !important;
  right: 19rem !important;
}
.patient-video .doctor-toolbar {
  margin-left: 21rem !important;
}
.refresh-text {
  margin-left: 20%;
  width: 53rem;
  text-align: center;
}

/* hdpi screen laptop */
@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  .local video {
    width: 19rem !important;
  }
  .not-connected {
    top: 2rem !important;
    right: 10rem !important;
  }
  .self-video {
    right: 11rem !important;
  }
}

/* mdpi screen laptop */
@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .local video {
    width: 16rem !important;
  }
  .not-connected {
    top: 2rem !important;
    right: 2rem !important;
  }
  .self-video {
    right: 4rem !important;
  }
  .room {
    margin-left: initial;
    min-height: 33rem !important;
  }
  .twilio-toolbar {
    flex-direction: column !important;
    display: flex !important;
    width: initial;
    left: 5% !important;
    top: 40% !important;
  }
  .network-indicator {
    width: 59% !important;
    margin: -4rem !important;
    top: -6rem;
    position: relative !important;
  }
  .local video {
    width: 15rem !important;
    top: 0rem !important;
    left: -4rem !important;
  }
  .loader {
    width: 13rem;
    margin: -12rem auto !important;
  }
}

/* pixel 2 portrait */
@media only screen and (min-device-width: 380px) and (max-device-width: 480px) and (orientation: portrait) {
  .room {
    background: #1b2028;
    min-height: 18rem;
    margin-top: 55px;
    margin-left: 5px;
    margin-right: -5px;
  }
  .client-video {
    min-height: 10rem !important;
  }
  .patient-video .participant-status {
    top: 12rem !important;
    right: 3rem !important;
    width: 20rem;
    font-size: 0.9rem;
  }
  .participant-status {
    top: 14rem !important;
    width: 20rem !important;
    font-size: 0.8rem;
    right: 2rem !important;
  }
  .self-video {
    max-width: 8em !important;
    right: 5rem !important;
    top: 10rem !important;
  }
  .patient-video .room {
    margin: 5% 3%;
  }
  .patient-video .self-video {
    right: 3rem !important;
    top: 4rem !important;
  }
  .local-participants .participant video {
    min-height: 13rem !important;
    min-width: 300px !important;
  }
  .participant video {
    height: 15rem !important;
  }
  .twilio-toolbar {
    /* margin: -5em auto !important; */
    transition: all 0.5s ease-out;
    position: absolute;
    /* padding-left:0%; */
    top: 93%;
    z-index: 999;
    border-radius: 10px;
    background: #16171a;
    box-shadow: 9px 9px 16px #0a0b0c, -9px -9px 16px #222328;
    display: flex;
    flex-direction: row;
    grid-gap: 0.2rem;
    transform: none;
    left: 32%;
    width: initial;
  }
  .callBtn {
    font-size: 0.6em;
  }
  .chat-window {
    width: 22rem !important;
    top: 11.5rem !important;
    right: 2rem !important;
    height: 16.5rem !important;
  }
  .patient-video .chat-window {
    width: 20rem !important;
    top: 3.5rem !important;
    right: 3rem !important;
    height: 15rem !important;
  }
  .waiting-room {
    width: 100% !important;
  }
  .waiting-text {
    padding: 0rem !important;
  }
  .not-connected {
    top: 4rem !important;
    right: 2rem !important;
  }
  .patient-status {
    top: 20rem !important;
    width: 22rem !important;
    font-size: 0.8rem;
  }
  .refresh-text {
    margin-left: 0rem !important;
    width: unset !important;
  }
  .doctor-toolbar {
    margin-left: 6rem !important;
  }
  .doctor-toolbar .twilio-toolbar {
    margin: 0rem auto !important;
  }
  .patient-video .patient-status {
    top: 11rem !important;
    right: 1.5rem !important;
  }
  .patient-video .doctor-toolbar {
    margin-left: 8.5rem !important;
  }
  .network-indicator {
    width: 60% !important;
    margin: -8% !important;
    top: 25px !important;
    position: relative !important;
  }

  .connection-string {
    top: 53px !important;
    position: relative !important;
    left: 2% !important;
  }
  .patient-video .connection-sts {
    margin-left: 50% !important;
  }
  .patient-video .network-indicator {
    width: 38.5% !important;
    margin: -7.5% !important;
  }
  .patient-video .network-sts {
    margin: -5% !important;
  }

  .local video {
    width: 8rem !important;
    display: block;
    margin: 0 auto;
    border-radius: 6px;
    transform: scaleX(-1);
    object-fit: cover;
    position: relative;
    /* top: -4rem; */
    top: 0rem;
    /* left: 1rem; */
    left: 0rem;
  }
  .not-connected {
    top: 9rem !important;
    right: 4rem !important;
  }
  .loader {
    width: 20rem !important;
    margin: -13rem auto !important;
  }
}

/* iphone5 portrait */
@media only screen and (min-device-width: 300px) and (max-device-width: 325px) and (orientation: portrait) {
  .room {
    background: #1b2028;
    min-height: 14rem;
    margin-top: 55px;
    margin-left: 5px;
    margin-right: -5px;
  }
  .local video {
    width: 5rem !important;
  }
  .client-video {
    min-height: 10rem !important;
  }
  .self-video {
    max-width: 8em !important;
    right: 4rem !important;
    top: 9rem !important;
  }
  .patient-video .room {
    margin: 5% 4%;
  }
  .patient-video .self-video {
    right: 1rem !important;
    top: 4rem !important;
  }
  .local-participants .participant video {
    min-height: 13rem !important;
    min-width: 300px !important;
  }
  .participant video {
    height: 11rem !important;
  }
  .twilio-toolbar {
    transition: all 0.5s ease-out;
    position: absolute;
    /* padding-left:0%; */
    top: 93%;
    z-index: 999;
    border-radius: 10px;
    background: #16171a;
    box-shadow: 9px 9px 16px #0a0b0c, -9px -9px 16px #222328;
    display: flex;
    flex-direction: row;
    grid-gap: 0.2rem;
    transform: none;
    /* left:20%; */
    left: 28%;
    width: initial;
  }
  .callBtn {
    font-size: 0.6em;
  }
  .patient-video .participant-status {
    top: 9.5rem !important;
    right: 3rem !important;
    width: 15rem;
    font-size: 0.65rem;
  }
  .participant-status {
    top: 12.5rem !important;
    right: 2rem !important;
    font-size: 0.65rem;
    width: 14.5rem;
  }
  .loader {
    width: 9rem !important;
    margin: -9rem auto !important;
  }
  .chat-window {
    width: 16rem !important;
    top: 11rem !important;
    right: 2rem !important;
    height: 13rem !important;
  }
  .patient-video .chat-window {
    width: 14.5rem !important;
    top: 3rem !important;
    right: 3rem !important;
    height: 11rem !important;
  }
  .waiting-room {
    width: 100% !important;
  }
  .waiting-text {
    padding: 0rem !important;
  }
  .not-connected {
    top: 12rem !important;
    right: 0rem !important;
  }
  .patient-status {
    top: 17rem !important;
    width: 17rem !important;
    font-size: 0.8rem;
  }
  .refresh-text {
    margin-left: 0rem !important;
    width: unset !important;
  }
  .doctor-toolbar {
    margin-left: 3rem !important;
  }
  .doctor-toolbar .twilio-toolbar {
    margin: 0rem auto !important;
  }
  .patient-video .patient-status {
    top: 11rem !important;
    right: 1.5rem !important;
  }
  .patient-video .doctor-toolbar {
    margin-left: 6rem !important;
  }
  .network-indicator {
    width: 50% !important;
    margin: -8% !important;
    top: 25px !important;
    position: relative !important;
  }
  .connection-string {
    width: 60% !important;
    top: 49px !important;
    position: relative !important;
    left: 2% !important;
  }
  .patient-video .connection-string {
    width: 60% !important;
  }
  .patient-video .connection-sts {
    margin-left: 0% !important;
  }
  .patient-video .network-indicator {
    width: 48.5% !important;
    margin: -7.5% !important;
  }
  .patient-video .network-sts {
    margin: -5% !important;
  }
  .local {
    top: -50px;
    position: relative;
    left: 45px;
  }
  .not-connected {
    top: 9rem !important;
    right: 3rem !important;
  }
}

/* iphone 6/7/8 plus portrait*/
@media only screen and (min-device-width: 414px) and (max-device-width: 750px) and (orientation: portrait) {
  .room {
    background: #1b2028;
    min-height: 18rem;
    margin-top: 50px;
    margin-left: -14px;
    margin-right: -30px;
  }

  .twilio-toolbar {
    transition: all 0.5s ease-out;
    position: absolute;
    /* padding-left:0%; */
    top: 93%;
    z-index: 999;
    border-radius: 10px;
    background: #16171a;
    box-shadow: 9px 9px 16px #0a0b0c, -9px -9px 16px #222328;
    display: flex;
    flex-direction: row;
    grid-gap: 0.2rem;
    transform: none;
    /* left:20%; */
    left: 35%;
    width: initial;
  }

  .not-connected {
    top: 9rem !important;
    right: 4rem !important;
  }

  .network-indicator {
    width: 66% !important;
    margin: -8% !important;
    top: 20px !important;
    position: relative !important;
  }

  .connection-string {
    /* width: initial; */
    top: 53px !important;
    position: relative !important;
    left: 2% !important;
  }
  .local {
    top: -65px;
    position: relative;
    left: 25px;
    margin: 10px;
  }

  .local video {
    top: 2rem !important;
    left: 0rem !important;
  }
}

/* ipad landscape */
@media only screen and (min-width: 1024px) and (max-width: 1100px) and (orientation: landscape) {
  .loader {
    margin: -6rem 18rem !important;
  }
}

/* ipad pro portrait */
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
  .patient-video .participant-status {
    top: 24rem !important;
    right: 22rem !important;
  }
  .participant-status {
    top: 30rem !important;
    width: 37rem !important;
    right: 8rem !important;
  }
  .loader {
    margin: 10rem 18rem !important;
  }
  .patient-status {
    width: 70rem !important;
  }
  .doctor-toolbar {
    margin-left: 0rem !important;
  }
  .refresh-text {
    margin-left: 20%;
    width: 47rem !important;
  }
  .patient-video .patient-status {
    top: 18rem !important;
    right: 17rem !important;
  }
  .patient-video .doctor-toolbar {
    margin-left: 18rem !important;
  }
  .network-indicator {
    width: 59% !important;
    margin: -8% !important;
    top: -74px !important;
    position: relative !important;
  }
  .patient-video .network-indicator {
    width: 31% !important;
    margin: -2% 0 0 !important;
  }
  .local video {
    top: 0rem !important;
    left: -2rem !important;
    position: relative;
  }
}

/* ipad pro landscape */
@media only screen and (min-width: 1300px) and (max-width: 1400px) and (orientation: landscape) {
  .loader {
    margin: -6rem 18rem !important;
  }
}

/* ipad portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 800px) and (orientation: portrait) {
  .local video {
    top: 0rem !important;
    left: -3rem !important;
    position: relative;
  }
  .loader {
    margin: -2rem auto !important;
  }
}

/* galaxy, nexus 5, motog4 portrait */
@media only screen and (min-device-width: 355px) and (max-device-width: 375px) and (orientation: portrait) {
  .room {
    background: #1b2028;
    min-height: 16rem;
    margin-top: 55px;
    margin-left: -12px;
    margin-right: -28px;
  }

  .local video {
    width: 7rem !important;
  }
  .self-video {
    max-width: 8em !important;
    top: 9rem !important;
    right: 5rem !important;
  }
  .not-connected {
    top: 9rem !important;
    right: 5rem !important;
  }
  .patient-video .room {
    margin: 5% 3%;
  }
  .patient-video .self-video {
    right: 2rem !important;
    top: 4rem !important;
  }
  .local-participants .participant video {
    min-height: 13rem !important;
    min-width: 300px !important;
  }
  .participant video {
    height: 13rem !important;
  }
  .twilio-toolbar {
    transition: all 0.5s ease-out;
    position: absolute;
    top: 92%;
    z-index: 999;
    border-radius: 10px;
    background: #16171a;
    box-shadow: 9px 9px 16px #0a0b0c, -9px -9px 16px #222328;
    display: flex;
    flex-direction: row;
    grid-gap: 0.2rem;
    transform: none;
    left: 32%;
    width: initial;
  }
  .callBtn {
    font-size: 0.6em;
  }
  .patient-video .participant-status {
    top: 11rem !important;
    right: 3.25rem !important;
    font-size: 0.8rem;
    width: 17rem !important;
  }
  .client-video {
    min-height: 10rem !important;
  }
  .participant-status {
    top: 13rem !important;
    width: 17rem !important;
    font-size: 0.75rem;
    right: 1rem !important;
  }
  .loader {
    width: 9rem !important;
    margin: -9rem auto !important;
  }
  .chat-window {
    width: 19.5rem !important;
    top: 11rem !important;
    right: 2rem !important;
    height: 15rem !important;
  }
  .patient-video .chat-window {
    width: 18.5rem !important;
    top: 3rem !important;
    right: 2rem !important;
    height: 14rem !important;
  }
  .waiting-room {
    width: 100% !important;
  }
  .waiting-text {
    padding: 0rem !important;
  }

  .patient-status {
    top: 20rem !important;
    width: 19rem !important;
    font-size: 0.8rem;
  }
  .refresh-text {
    margin-left: 0rem !important;
    width: unset !important;
  }
  .doctor-toolbar {
    margin-left: 6rem !important;
  }
  .doctor-toolbar .twilio-toolbar {
    margin: 0rem auto !important;
  }
  .patient-video .patient-status {
    top: 11rem !important;
    right: 1.5rem !important;
  }
  .patient-video .doctor-toolbar {
    margin-left: 7rem !important;
  }
  .network-indicator {
    width: 50% !important;
    margin: -8% !important;
    top: 25px !important;
    position: relative !important;
  }
  .connection-string {
    width: 60% !important;
    top: 49px !important;
    position: relative !important;
    left: 2% !important;
  }
  .patient-video .connection-sts {
    margin-left: 50% !important;
  }
  .patient-video .network-indicator {
    width: 43.5% !important;
    margin: -7.5% !important;
  }
  .patient-video .network-sts {
    margin: -5% !important;
  }

  .local video {
    width: 190%;
    display: block;
    margin: 0 auto;
    border-radius: 6px;
    transform: scaleX(-1);
    object-fit: cover;
    position: relative;
    top: 31px;
    left: -25px;
  }
  .local {
    top: -80px;
    position: relative;
    left: 85px;
  }
}

/* motog4, nexus landscape */
@media only screen and (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {
  .room {
    min-height: 17rem;
  }
  .loader {
    width: 9rem !important;
    margin: -20rem auto !important;
  }
  .participant video {
    width: 100% !important;
  }
  .local video {
    width: 8rem !important;
    display: block;
    margin: 0 auto;
    border-radius: 6px;
    transform: scaleX(-1);
    object-fit: cover;
    position: relative;
    top: -12rem;
    left: 6rem;
  }
  video {
    width: 65%;
    display: block;
    margin: 0 auto;
    border-radius: 6px;
    transform: scaleX(-1);
    object-fit: cover;
    position: relative;
    top: -180px;
    left: 35px;
  }

  .client-video {
    min-height: 10rem !important;
  }
  .participant video {
    height: 18rem !important;
    position: relative !important;
    top: auto !important;
  }
  .self-video {
    top: 15rem !important;
    right: 5rem !important;
  }
  .patient-video .self-video {
    right: 5rem !important;
    top: 5rem !important;
  }
  .videoChat {
    height: 100% !important;
  }
  .patient-video .room {
    width: 100vw;
    margin: inherit;
  }
  .col-sm-3 {
    flex: auto;
    max-width: 35%;
  }
  .patient-video .col-sm-6 {
    max-width: inherit;
  }
  .patient-video .participant-status {
    top: 17rem !important;
    right: -16rem !important;
  }
  .participant-status {
    top: 28rem !important;
    width: 35rem !important;
    right: 3rem !important;
  }
  .chat-window {
    width: 18rem !important;
    top: 13.5rem !important;
    right: 2rem !important;
    height: 23.5rem !important;
  }
  .patient-video .chat-window {
    width: 18rem !important;
    top: 2rem !important;
    right: -15rem !important;
    height: 22rem !important;
  }
  .not-connected {
    top: 14rem !important;
    right: 6rem !important;
  }
  .patient-status {
    top: 27rem !important;
    right: 11rem;
    width: 20rem !important;
  }
  .patient-video .patient-status {
    top: 14rem !important;
    right: -10.5rem !important;
  }
  .doctor-toolbar .twilio-toolbar {
    margin: 0rem auto !important;
  }
  .patient-video .doctor-toolbar {
    margin-left: 14rem !important;
  }
  .refresh-text {
    margin-left: 0%;
    width: 39rem;
  }
  .network-indicator {
    width: 60% !important;
    margin: -10% !important;
    top: -55px !important;
    position: relative;
  }
  .patient-video .connection-sts {
    margin-left: 0% !important;
  }
  .patient-video .network-indicator {
    width: 40.5% !important;
    margin: -7.5% !important;
  }
  .patient-video .network-sts {
    margin: -5% !important;
  }
  .patient-video .connection-string {
    width: 60% !important;
  }
}

/* iphone 6/7/8 landscape */
@media only screen and (min-width: 650px) and (max-device-width: 690px) and (orientation: landscape) {
  .local video {
    width: 8rem !important;
    display: block;
    margin: 0 auto;
    border-radius: 6px;
    transform: scaleX(-1);
    object-fit: cover;
    position: relative;
    top: 1rem;
    left: 0rem;
  }
  .participant video {
    height: 82vh !important;
  }
  .loader {
    width: 9rem !important;
    margin: -13rem auto !important;
  }
}

/* pixel2 landscape */
@media only screen and (min-width: 700px) and (max-device-width: 800px) and (orientation: landscape) {
  .self-video {
    top: 16rem !important;
    right: 5rem !important;
  }
  .client-video {
    min-height: 10rem !important;
  }
  .patient-video .self-video {
    right: 5rem !important;
    top: 5rem !important;
  }
  .videoChat {
    height: 100% !important;
  }
  .patient-video .room {
    width: 100vw;
    margin: inherit;
  }
  .col-sm-3 {
    flex: auto;
    max-width: 35%;
  }
  .patient-video .col-sm-6 {
    max-width: inherit;
  }
  .patient-video .participant-status {
    top: 22rem !important;
    right: -17rem !important;
  }
  .participant-status {
    top: 35rem !important;
    width: 38rem !important;
    right: 4rem !important;
  }
  .participant video {
    height: 82vh !important;
  }
  .chat-window {
    width: 20rem !important;
    top: 14.5rem !important;
    right: 2rem !important;
    height: 29.5rem !important;
  }
  .patient-video .chat-window {
    width: 21rem !important;
    top: 2rem !important;
    right: -19rem !important;
    height: 28rem !important;
  }
  .not-connected {
    top: 15rem !important;
    right: 4rem !important;
  }
  .patient-status {
    top: 28rem !important;
    width: 25rem !important;
    right: 11rem;
  }
  .patient-video .patient-status {
    top: 11rem !important;
    right: -13.5rem !important;
  }
  .doctor-toolbar .twilio-toolbar {
    margin: 0rem auto !important;
  }
  .patient-video .doctor-toolbar {
    margin-left: 16rem !important;
  }
  .refresh-text {
    margin-left: 0%;
    width: 41rem;
  }
  .patient-video .connection-string {
    width: 80% !important;
  }
  .patient-video .connection-sts {
    margin-left: 0% !important;
  }
  .patient-video .network-indicator {
    width: 40.5% !important;
    margin: -7.5% !important;
  }
  .patient-video .network-sts {
    margin: -5% !important;
  }
  .loader {
    width: 9rem !important;
    margin: -20rem auto !important;
  }
  .local video {
    width: 8rem !important;
    display: block;
    margin: 0 auto;
    border-radius: 6px;
    transform: scaleX(-1);
    object-fit: cover;
    position: relative;
    top: -13rem;
    left: 4rem;
  }
}

/* pixel2 xl landscape */
@media only screen and (min-width: 800px) and (max-device-width: 900px) and (orientation: landscape) {
  .local video {
    width: 8rem !important;
    display: block;
    margin: 0 auto;
    border-radius: 6px;
    transform: scaleX(-1);
    object-fit: cover;
    position: relative;
    top: 1rem;
    left: 0rem;
  }
  .participant video {
    height: 82vh !important;
  }
  .loader {
    width: 9rem !important;
    margin: -13rem auto !important;
  }
}

.chat-window {
  width: 25rem;
  position: absolute;
  top: 10rem;
  right: 5rem;
  height: 28rem;
  background-color: white;
  display: flex;
  padding: 1rem 1rem;
  flex-flow: column wrap;
}
.patient-video .chat-window {
  width: 21rem;
  top: 3.5rem;
  right: 17rem;
  height: 28rem;
}
.messages {
  overflow-y: scroll;
  height: 25rem;
  width: 20rem;
  padding: 1rem;
  padding-bottom: 3rem !important;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul li {
  display: inline-block;
  clear: both;
  padding: 10px;
  border-radius: 30px;
  margin-bottom: 5px;
  font-family: Helvetica, Arial, sans-serif;
}

.sender {
  background: #eee;
  float: left;
  max-width: inherit;
  word-break: break-word;
}

.receiver {
  float: right;
  background: #0084ff;
  color: #fff;
  max-width: inherit;
  word-break: break-word;
}

.sender + .receiver {
  border-bottom-right-radius: 5px;
}

.receiver + .receiver {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.receiver:last-of-type {
  border-bottom-right-radius: 30px;
}

.sendMess textarea,
.sendMess button {
  max-height: 50px;
}
.sendMess button {
  border: none;
  width: 20%;
  background-color: white;
}
.sendMess textarea {
  width: 75%;
  margin-bottom: 4px;
  margin-left: -5px;
  margin-right: -4px;
  padding: 5px;
  resize: none;
  border: none;
  outline: none;
}
/* 
.network-indicator {
  width: 60% !important;
  margin: -10% !important;
  top:-70px!important;
  position: relative !important;
} */

textarea:focus,
button:focus {
  outline: none;
}
.loading {
  margin: 50% auto;
}
.sendMess {
  position: absolute;
  width: 100%;
  margin: 0 auto;
  display: flex;
  bottom: 0rem;
}
