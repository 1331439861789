html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.MuiTypography-body1 {
  font-family: 'Poppins', sans-serif !important;
}
header {
  left: 0px !important;
  right: 0px !important;
}
.logo-img {
  width: 110px;
}
.Topcol {
  width: 97%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 0;
}
.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  opacity: 1 !important;
  background: #39b119 !important;
}
.MuiAvatar-circle:hover svg {
  fill: #fff !important;
}
.dashboard-menu .MuiSvgIcon-root {
  fill: #fff !important;
}
.MuiSvgIcon-fontSizeSmall {
  fill: #000 !important;
}
.Plancard:visited {
  border: 1px #0d1843 solid;
}
.Plancard:focus {
  border: 1px #0d1843 solid;
}
.Topcol h3 {
  color: rgba(0, 0, 0, 0.8);
}
code {
  font-family: 'Poppins';
}

button {
  outline: none !important;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

#root {
  overflow: hidden;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: 'Poppins', sans-serif !important;
}
.MuiTableCell-root {
  font-family: 'Poppins', sans-serif !important;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.disabled:focus {
  border-color: #0d1843;
}
.MuiButton-root.Mui-disabled {
  color: rgba(255, 255, 255, 0.8) !important;
  border-color: rgba(0, 0, 0, 0.26);
  text-transform: capitalize !important;
}
.MuiStepIcon-root.MuiStepIcon-active {
  color: #21caf1 !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #21caf1 !important;
}

.MuiSelect-select.MuiSelect-select {
  color: rgba(0, 0, 0, 0.54);
}

.text-center {
  text-align: center;
}

.position-relative {
  position: relative;
}

.text-white {
  color: white;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.scheduler-view {
  width: 65% !important;
}

.rbc-row-content {
  overflow-y: scroll;
}

.rbc-row-content {
  font-family: 'Ubuntu-R' !important;
  scrollbar-width: thin;
  scrollbar-color: grey #cecece;
}

.rbc-row-content {
  scrollbar-width: thin;
  scrollbar-color: grey #cecece;
  padding: 10px 0px;
}

.rbc-row-content::-webkit-scrollbar {
  width: 12px;
}

.rbc-row-content::-webkit-scrollbar-track {
  background: #cecece;
}

.rbc-row-content::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 20px;
  border: 3px solid #cecece;
}

.rbc-row-content::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 10px;
}

.rbc-row-content::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 10px;
}

.MuiTab-textColorInherit.Mui-selected {
  background-color: #0d46c1 !important;
  color: #fff !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.e-schedule {
  border-radius: 15px;
}
.e-tbar-pos {
  background-color: #dde8ff !important;
}
.e-schedule .e-schedule-toolbar .e-tbar-btn {
  background: #dde8ff !important;
}
.e-schedule .e-event-resize.e-top-handler,
.e-schedule .e-event-resize.e-bottom-handler {
  height: 1px !important;
  width: 100%;
}
.mobile-orientation {
  width: 100%;
  display: none !important;
  background: #0d1843;
  flex-direction: column;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999999999;
}
.mobile-orientation img {
  height: 100px;
  margin-bottom: 15px;
}
.orientation-col {
  width: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mobile-orientation p {
  color: #fff;
  font-size: 18px;
}
.profile-top {
  position: relative;
}
.MuiInput-input {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 0.54) !important;
}
.profile-top:before {
  background: linear-gradient(45deg, #ff60ea, #063bc4);
  height: 13rem;
  content: '';
  position: absolute;
  left: 0px;
  width: 100%;
}
/* Doctor list */
.doctor-col {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  margin-bottom: 15px;
}
.doctorlist-top {
  display: flex;
  align-items: center;
  border-bottom: dashed rgba(0, 0, 0, 0.1) 1px;
  padding-bottom: 20px;
  margin-bottom: 15px;
}
.doctorlist-top img {
  margin-right: 20px;
}
.doctorlist-top h6 {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif !important;
  margin-bottom: 5px;
}
.doctorlist-top p {
  font-size: 12px;
  color: #2b3984;
  font-weight: 400;
  margin: 0 0 5px;
}
.doctorlist-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.doctorlist-bottom .fees {
  font-weight: 700;
  font-size: 16px;
  color: #fc8951;
  margin: 0 0 5px;
  font-family: 'Poppins', sans-serif !important;
}
.consult-text {
  font-size: 14px;
  color: #fc8951;
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
}
.Consult-btn {
  background: linear-gradient(135deg, #3e3be8 0%, #3267f1 100%);
  height: 40px;
  border-radius: 10px;
  padding: 0 20px;
  color: #fff !important;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-responsiveimg {
  display: none;
}
/* end doctor list */
.card-img {
  width: 100%;
  position: absolute;
  left: 0px;
  top: 50%;
}

.card-img img {
  width: 100%;
}

.profile-col {
  width: 500px;
  margin: 0px auto;
  position: relative;
  padding-top: 15px;
}

.profile-row {
  display: flex;
}

.top-logo {
  position: relative;
}

.profile-info {
  text-align: right;
  width: 65%;
  margin-right: 2rem;
}

.profile-info h3 {
  font-size: 24px;
  font-weight: 900;
  color: #fff;
  margin-bottom: 0px;
  height: 5rem;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}

.profile-info p {
  font-weight: 700;
  font-size: 18px;
  color: #0e1226;
  margin-top: 10px;
  word-break: break-all;
  white-space: initial;
}

.profile-img {
  width: 180px;
  height: 180px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  margin-top: -30px;
  overflow: hidden;
  border: 5px solid white;
  flex: 0 0 180px;
}

.PricingTab .Blanktab {
  min-width: 25px !important;
  max-width: 25px !important;
}
.TeleBtnDisabled img {
  filter: grayscale(1);
  margin-right: 10px;
}
.TeleBtn img {
  margin-right: 10px;
}
.profile-img img {
  width: 100%;
  height: 100%;
}

.barcode-col {
  text-align: center;
  margin-top: 30px;
}

.barcode-col h5 {
  font-size: 24px;
  color: #0e1226;
}

.barcode-col p {
  font-size: 18px;
  color: #0e1226;
  font-weight: bold;
}

.code-box {
  width: 250px;
  height: 250px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 1px 3px 7px 0px rgba(0, 0, 0, 0.2);
  margin: 0px auto 20px;
  background: #fff;
}

.code-box canvas {
  width: 100% !important;
  height: 100% !important;
}

.Cardprofile {
  width: 650px;
  margin: 0px auto;
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
}

.contact-col {
  background: linear-gradient(45deg, #ff60ea, #063bc4);
  padding: 10px 30px;
  border-radius: 40px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  width: 500px;
  font-weight: bold;
  margin: 0px auto 15px;
}

.barcode-col h6 {
  font-size: 16px;
  font-weight: 600;
  color: #0e1226;
}
.MuiOutlinedInput-input {
  padding: 14.5px 14px;
}
.MuiInputLabel-outlined {
  z-index: 1;
  transform: translate(14px, 18px) scale(1);
  pointer-events: none;
}
.MuiOutlinedInput-root {
  position: relative;
  border-radius: 8px !important;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 5px;
}

::-webkit-scrollbar:horizontal {
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #0d1843;
  border-radius: 0px;
  border: 0px solid #8396e6;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #5d70b9;
}

::-webkit-scrollbar-track {
  border-radius: 0px;
}
.MuiCard-root {
  overflow: auto !important;
}
.service-popup .MuiCard-root {
  overflow: hidden !important;
}
.card-title {
  font-size: 1rem;
}
.MuiCard-root table {
  overflow-x: auto !important;
}
.content {
  overflow: hidden;
}
.update-profile .MuiTextField-root {
  width: 100% !important;
}
.no-data-img {
  width: 200px;
  display: block;
  margin: 0px auto;
}
.no-data-img img {
  width: 100%;
}
.payment-erroe {
  display: flex;
  justify-content: center;
}
.payment-erroe .fa {
  margin-right: 15px;
  color: #eea72c;
  font-size: 28px;
}
.payment-erroe h2 {
  margin-top: 0px;
}
.payment-success {
  text-align: center;
}
/* .MuiTab-textColorPrimary.Mui-selected {
  color: #fff !important;
  background: #374370;
} */
.custom-choose input[type='radio'] {
  /* display: none; */
  position: absolute;
  left: 15px;
  top: -10px;
}
.custom-choose {
  position: relative;
}
.custom-choose input[type='radio'] + label,
.custom-choose input[type='radio']:not(checked) + label,
.custom-choose input[type='checkbox'] + label,
.custom-choose input[type='checkbox']:not(checked) + label {
  padding: 0;
  border-radius: 7px;
  text-align: center;
  font-size: 13px;
  margin-right: 0;
  transition: 0.4s;
  width: 100%;
  overflow: hidden;
}
.custom-choose input[type='radio'] + label .price .price {
  font-size: 20px;
}
.custom-choose input[type='radio'] + label h5 {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #0d1843;
  margin-bottom: 15px;
  margin-top: 0px;
}
.custom-choose input[type='radio'] + label h4 {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #0d1843;
  margin-bottom: 15px;
  margin-top: 0px;
}
.custom-choose input[type='radio'] + label h2 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #0d1843;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif !important;
}
.custom-choose input[type='radio'] + label h3 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  margin-bottom: 0px;
  background: #0d1843;
  padding: 5px;
}
.custom-choose input[type='radio'] + label p {
  font-size: 16px;
  margin: 0 0 10px;
}
.custom-choose input[type='radio'] + label .pay-row h6 {
  font-size: 16px;
}
.custom-choose input.plan1[type='radio'] + label,
.custom-choose input[type='radio']:not(checked) + label {
  background: #fff;
  font-family: 'Poppins', sans-serif !important;
}
.custom-choose input.plan2[type='radio'] + label,
.custom-choose input[type='radio']:not(checked) + label {
  background: #fff;
}
.custom-choose input.plan3[type='radio'] + label,
.custom-choose input[type='radio']:not(checked) + label {
  background: #fff;
}
.custom-choose input[type='radio']:checked + label h6 {
  color: #fff !important;
}
.custom-choose input[type='radio']:checked + label h5 {
  color: #fff !important;
}
.custom-choose input[type='radio']:checked + label h4 {
  color: #fff !important;
}
.custom-choose input[type='radio']:checked + label h3 {
  color: #0e1d58 !important;
  background-color: #fff;
}
.custom-choose input[type='radio']:checked + label p {
  color: #fff !important;
}
.custom-choose input[type='radio']:checked + label .price {
  color: #fff !important;
}
.custom-choose input[type='radio']:checked + label .price span {
  color: #fff !important;
}
.custom-choose input[type='radio']:checked + label .price-discount span {
  color: #fff !important;
}
.custom-choose input[type='radio']:checked + label,
.custom-choose input[type='checkbox']:checked + label {
  position: relative;
  color: #fff !important;
  transition: 0.4s;
  background: #0e1d58;
}
.MuiStepIcon-root.MuiStepIcon-active {
  color: #0e1d58 !important;
}
.custom-choose input[type='radio']:checked + label::before,
.custom-choose input[type='checkbox']:checked + label::before {
  content: '✓';
  position: absolute;
  left: 15px;
  z-index: 9;
  top: 12px;
  width: 15px;
  height: 15px;
  background: #0e1d58;
  border-radius: 50%;
  padding: 1px;
  color: #fff;
  font-size: 10px;
  line-height: 14px;
  font-weight: bold;
}
.payment-plan {
  width: 100%;
  font-family: 'Poppins', sans-serif !important;
}
.container2 {
  width: 1200px;
  margin: 100px auto 0;
  font-family: 'Poppins', sans-serif !important;
}
.plan-details {
  width: 90%;
  background-color: #fff;
  padding: 5%;
}
ul.st-pricing-feature-list li {
  width: 100% !important;
}
.plan-info {
  padding: 20px;
  width: 89%;
  line-height: 24px;
  min-height: 190px;
}
.payment-frequency select {
  height: 30px;
}
.payment-frequency:nth-child(2) {
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  margin-bottom: 25px;
}
.payment-frequency:nth-child(1) {
  margin-bottom: 0px !important;
}
.payment-frequency:nth-child(5) {
  margin-bottom: 0px !important;
}
.plan-details h4 {
  margin: 0px;
}
.plan-option {
  width: 200px;
}
.plan-details .table {
  width: 100%;
  border-spacing: 0px !important;
}
.plan-details .table td,
th {
  text-align: left;
  padding: 10px;
}
.plan-details .table tbody tr:nth-child(odd) {
  background: #fff;
}
.plan-details .table tbody tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.1);
}
.plan-details .table th {
  background-color: rgba(0, 0, 0, 0.1);
}
.amount-row h4 {
  color: #fff;
  margin-bottom: 0px;
  padding: 10px 0 10px 5px;
}
.tax-price {
  display: flex;
  justify-content: space-between;
}
.text-right {
  display: flex;
  justify-content: flex-end;
}
.amount-row {
  background-color: #0d1843;
  padding: 0 0 0 10px;
  align-items: center;
}
.total-amount {
  background-color: #080f2b;
  color: #fff;
  padding: 0 20px;
  display: flex;
  justify-content: flex-end;
  height: 40px;
  align-items: center;
}
.payment-frequency,
.plan-amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.plan-amount,
.frequency-right {
  flex: 0 0 60%;
}
.plan-box {
  padding: 25px;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.payment-plan ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.payment-plan ul li {
  width: 31%;
  padding: 0 10px;
}
.vital-col {
  padding: 0px 5px 20px 10px;
  display: flex;
  flex-direction: column;
}
.MedicationCol table th,
.MedicationCol table td {
  font-size: 14px;
}
.appointment-tab .MuiTabs-flexContainer {
  justify-content: space-between;
}
.appointment-tab {
  background-color: transparent !important;
}
#div1 .MuiTableCell-root {
  font-size: 14px !important;
}
/* .MuiDialog-container .MuiPaper-rounded {
  border-radius:0px !important;
} */

.makeStyles-table-75 th {
  font-size: 15px;
  font-weight: bold;
}

.MuiTableCell-root {
  padding: 5px !important;
}

.medications-box {
  border: 2px solid #059dfd;
  padding: 10px;
  border-radius: 5px;
  margin: 15px 0px;
  width: 50%;
}

.medications-box h4 {
  margin-bottom: 0px;
  margin-top: 10px;
}
.update-profile {
  width: 98%;
  display: flex;
  flex-wrap: wrap;
}

.update-profile-col {
  width: 50%;
}
.update-profile-col:nth-child(1) {
  padding-right: 25px;
}
.update-profile-col:nth-child(2) {
  padding-left: 25px;
}
.update-profile-col:nth-child(3) {
  padding-right: 25px;
}
.update-profile-col:nth-child(4) {
  padding-left: 25px;
}
.update-profile-col:nth-child(5) {
  padding-right: 25px;
}
.update-profile-col:nth-child(6) {
  padding-left: 25px;
}
.update-profile-col:nth-child(7) {
  padding-right: 25px;
}
.update-profile-col:nth-child(8) {
  padding-left: 25px;
}
.update-profile-col:nth-child(9) {
  padding-right: 25px;
}
.update-profile-col:nth-child(10) {
  padding-left: 25px;
}
.update-profile-col:nth-child(11) {
  padding-right: 25px;
}
.update-profile-col:nth-child(12) {
  padding-left: 25px;
}
.update-profile-col:nth-child(13) {
  padding-right: 25px;
}
.update-profile-col:nth-child(14) {
  padding-left: 25px;
}
/* .MuiTabs-flexContainer {
  overflow: auto;
} */
.payment-col {
  margin-top: 95px;
}
.payment-success h3 {
  margin: 20px 0 10px;
}
.payment-request p {
  text-align: center;
}
.payment-error-col {
  margin-top: 95px;
}
.payment-col h2 {
  font-size: 34px;
  color: #111d4e;
  margin-bottom: 10px;
}
.payment-col h3 {
  margin-bottom: 30px;
}
.jss156 {
  z-index: 99 !important;
}
.payment-btn {
  color: #fff !important;
  height: 45px;
  padding: 0px 30px;
  border-radius: 40px;
  text-transform: initial;
  background-color: #111d4e;
  display: inline-block;
  line-height: 45px;
  width: 150px;
  text-align: center;
  margin-top: 30px !important;
  cursor: pointer;
}
.payment-col h4 {
  color: #212121;
}
.payment-col h4 span {
  padding-left: 25px;
}
.payment-btn:hover {
  text-decoration: none !important;
}
.ui-card {
  width: 32%;
  box-shadow: 0 7px 15px rgba(0, 9, 128, 0.05),
    0 12px 28px rgba(0, 9, 128, 0.075);
  margin-bottom: 30px;
}

.card-header.bg-dark-gray {
  color: rgba(255, 255, 255, 1);
  background-color: #111d4e;
}

.card-header.bg-sky {
  color: rgba(255, 255, 255, 1);
  background-color: #111d4e;
}

.card-header {
  position: relative;
  background: #fff;
  background-color: rgb(255, 255, 255);
  overflow: hidden;
}

.pay-box {
  width: 800px;
  margin: 0px auto;
  box-shadow: 1px 2px 15px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
}

.pay-box h6 {
  margin: 0 0 15px !important;
  font-size: 16px;
  color: #666;
}

.pay-box h4 {
  margin: 0 0 15px !important;
  font-weight: 700;
  font-size: 22px;
}

.pay-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.pay-row h5 {
  color: #111d4e;
}

.pay-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.pay-top h4 {
  font-size: 18px;
  font-weight: 700;
  color: #111d4e;
  text-align: left;
}

.card-header {
  padding: 2rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.card-header::after {
  display: block;
  content: '';
  height: 100%;
  background: url(../src/images/hero-curve-layer.svg) bottom no-repeat;
  background-size: auto;
  background-size: contain;
  position: absolute;
  bottom: -2px;
  left: -6px;
  right: -6px;
  z-index: 1;
}

.Topheader {
  width: 1200px;
  margin: 0px auto;
  padding: 5px 0;
}

ul.Topnav {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: flex-end;
}

.price .period {
  font-size: 1.1rem;
  font-weight: 600;
}

.price span {
  color: #7c7c7c;
}

.price-discount span {
  color: #11224b;
}

.price .price {
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
  margin: 0 5px;
  color: #11224b;
}

.price .period {
  font-size: 1.1rem;
  font-weight: 600;
}

/* .card-body {
  background-color: #f6f7f8;
} */

.details-btn {
  background: transparent;
  color: #98348e;
  cursor: pointer;
}

.ui-card ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
}

.pricing-col .pricing-heding {
  text-align: center;
  margin-bottom: 50px;
  font-size: 30px;
}
.body-section {
  margin: 0px !important;
}
.fc-toolbar-title {
  color: #000 !important;
  border-radius: 10px;
  font-weight: 400;
}
.card-body {
  padding: 2rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  min-height: 300px;
}

.card-body ul li {
  padding: 3px;
  font-weight: normal;
}

.owl-stage .ui-card:nth-child(2) {
  /* margin-top: -1rem; */
  position: relative;
  z-index: 99;
}

.owl-stage .ui-card:nth-child(1) {
  margin-right: 1rem;
}

.owl-stage .ui-card:nth-child(3) {
  margin-left: 1rem;
}

.pricing-col h4 {
  text-align: center;
  margin-top: 40px;
}

.ui-card {
  background-color: #fff;
  border-radius: 0.5rem;
  text-align: center;
}

.ui-card h4 {
  font-size: 24px;
  color: #fff;
  margin: 15px 0;
}

.ui-card h6 {
  font-size: 18px;
  color: #fff;
  margin: 15px 0;
}

.shadow-xl {
  box-shadow: 0 7px 15px rgba(0, 9, 128, 0.05),
    0 12px 28px rgba(0, 9, 128, 0.075);
}

.owl-stage > div:nth-child(2) .ui-card .card-header {
  padding-top: 3rem;
}

.card-header.ui-gradient-peach {
  color: rgba(255, 255, 255, 0.85);
  background: -moz-linear-gradient(45deg, #fe60a1 0%, #ff8765 100%);
  background: linear-gradient(45deg, #fe60a1 0%, #ff8765 100%);
  position: relative;
  z-index: 9999;
}

.color-card .card-body {
  background-color: #fff;
}

.st-btn {
  background-color: #111d4e;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
}

.owl-stage {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.st-pricing-feature h2 {
  width: 88%;
}

.feature2 {
  padding-right: 0px;
}

.feature3 {
  padding-left: 0px;
  min-height: initial;
}

.phone-number {
  font-size: 24px;
  font-weight: bold;
  color: #11224b;
}

.doctor-text {
  font-size: 20px;
  font-weight: bold;
  color: #11224b !important;
}
/* super clinic */
.content-col {
  width: 1200px;
  margin: 0px auto;
}
.content-col h2 {
  font-size: 2.02rem;
  color: darkslategray;
  font-weight: normal;
}
.about-superclinic h2 {
  margin-top: 15%;
}
.content-col h2 span {
  text-transform: uppercase;
  color: rgb(49, 61, 106);
  font-weight: bold;
}
.content-col p {
  line-height: 24px;
  color: #8492a6;
}
/* .ulockd-service:before {
  background-image: url(../src/images/bgi4.png);
  background-position: left top;
  background-repeat: no-repeat;
  content: "";
  height: 100%;
  opacity: 0.04;
  position: absolute;
  width: 100%;
} */
.clinic-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}
.clinic-avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #ccc;
  overflow: hidden;
}
.clinic-avatar img {
  width: 100%;
  height: 120px;
}
.delete-modal {
  width: 400px;
  padding: 40px !important;
  min-height: 200px;
}
.edit-modal {
  width: 400px;
  padding: 30px 40px 90px;
  height: 70vh;
  overflow-y: auto;
}
.edit-modal h3 {
  color: rgba(46, 47, 51, 0.9);
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
  font-size: 18px;
}
.modal-top p {
  margin: 0px;
  font-weight: 500;
}
.modal-top p .fa {
  margin-left: 5px;
}
.modal-top p a {
  color: #131c37;
}
.delete-modal .fa {
  font-size: 24px;
  margin-right: 20px;
  color: #ff5252;
}
.delete-modal h3 {
  color: #ff5252;
  font-size: 20px;
  font-weight: 500;
  margin: 0px;
}
.yes-btn {
  background: #ff5252;
  width: 100px;
  margin-right: 15px;
  text-align: center;
}
.no-btn {
  background: #cccdd0;
  color: #727478 !important;
  width: 100px;
  text-align: center;
}
.delete-modal p {
  font-size: 16px;
  color: rgba(89, 92, 103, 0.7);
  margin-bottom: 50px;
  line-height: 24px;
}
.action-col {
  display: flex;
}
.delete {
  color: #ef1111;
}
.delete:hover {
  background: #ef1111;
  color: #fff;
}
.action-btn {
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
}
.view:hover {
  background: #0d1843;
  color: #fff;
}
.view {
  color: rgb(147, 148, 154);
}
.ulockd-pb20 {
  padding-bottom: 20px;
}
.main-content-coll {
  width: 92%;
  border-radius: 10px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  margin: 30px auto 20px;
  padding: 0 40px 25px;
  background-color: #fff;
}
.bgc-snowshade2 {
  position: relative;
}
.left-profile {
  border-right: 1px rgba(0, 0, 0, 0.1) solid;
  padding-right: 35px;
}
.superclinic-profile {
  width: 140px;
  position: relative;
  height: 140px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0px auto 30px;
}
.superclinic-profile img {
  width: 100%;
  height: 140px;
}
.service-strip {
  background: #111d4e;
  padding: 25px 0;
}
.service-strip h3 {
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 5px;
}
.service-strip h4 {
  margin-bottom: 0px;
  font-size: 1.3rem;
  color: #9fa1ad;
}
.bgc-snowshade2 {
  background: url(../src/images/super-bg.jpg) no-repeat center top;
}
.ulockd-service ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.ulockd-service ul li {
  width: 23%;
}
.service-strip {
  text-align: center;
}
.service-box p {
  margin-bottom: 0px;
}
.service-box {
  width: 90%;
  padding: 15px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.1);
}
.doctor-list {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.1);
}
.doctor-img {
  width: 80px;
  margin-right: 20px;
}
.doctor-info {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.doctor-info h4,
.doctor-info p {
  margin: 0px;
}
.clinic-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
}
.doctor-info h3 {
  font-size: 19px;
  margin-bottom: 10px;
}
.clinic-name p {
  margin: 0px;
  font-size: 14px;
}
.clinic-name h4 {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 16px;
}
.book-now {
  background: rgb(49, 61, 106);
  height: 35px;
  margin: 10px 0 5px;
}
.doc-pagination {
  display: flex;
  justify-content: flex-end;
}
.doc-pagination ul li {
  padding: 0px !important;
}
.superclinic-profile-img img {
  width: 100%;
  margin-bottom: 15px;
}
.superclinic-details {
  padding-left: 20px;
}
.superclinic-details p {
  font-family: 'Poppins', sans-serif !important;
  color: #9fa0a4;
  font-size: 15px;
  width: 80px;
  flex: 0 0 80px;
  margin: 0 10px 0 0;
}
.superclinic-details .form-group {
  display: flex;
  align-items: center;
}
.about-clinic {
  flex-direction: column;
}
.about-clinic h4 {
  width: 100%;
  margin-top: 10px;
}
.about-clinic p {
  line-height: 22px;
  color: #686a71;
  font-size: 15px;
  width: 98%;
}
.edit-button {
  color: #9e9fa5;
}
.edit-button:hover {
  color: #111d4e;
}
.edit-btn {
  background: #111d4e;
}
.edit-btn span {
  font-size: 24px;
  margin-right: 5px;
}
.btn {
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 40px;
  font-size: 14px;
  border-radius: 40px;
  color: #fff;
  text-align: center;
  line-height: 40px;
}
.cancel-btn {
  background: #ccc;
  margin-left: 15px;
}
.account-details p {
  margin: 0px;
  font-size: 14px;
}
label {
  font-family: 'Poppins', sans-serif !important;
}
.form-group .textarea div:focus + label {
  color: #3f51b5 !important;
}
.MuiFormControlLabel-label {
  color: #979797 !important;
}
.MuiSelect-select,
.MuiFormControlLabel-label {
  font-size: 14px !important;
  font-weight: 700 !important;
}
.MuiSelect-select.MuiSelect-select {
  font-weight: 700;
}
.textarea fieldset {
  border-color: rgba(0, 0, 0, 0.42);
}
.account-details p:first-child {
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px;
  color: #9fa0a4;
  margin: 0 10px 0 0;
}
.clinic-details p:first-child {
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px;
  color: #9fa0a4;
  flex: 0 0 100px;
  margin: 0 10px 0 0;
}
.clinic-details p {
  margin: 0px;
  font-size: 14px;
}
.clinic-details .form-group {
  display: flex;
  align-items: center;
}
.clinic-details input {
  height: auto;
  margin: 0px;
  padding: 5px;
  font-size: 14px;
}
.clinic-content-coll {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  margin: 0 auto 20px;
  padding: 0 0 25px 0;
  background-color: #fff;
  position: relative;
  overflow-y: hidden;
}
.clinic-top {
  width: 92%;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  align-items: center;
}
.top-col {
  display: flex;
  border-bottom: 1px #eee solid;
  padding-bottom: 10px;
  padding-top: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.back {
  position: absolute;
  left: 20px;
  top: 20px;
}
.back button {
  background: none;
  color: rgb(107 108 113);
  font-size: 14px;
}
.back button .fa {
  margin-right: 10px;
}
.MuiInputLabel-formControl {
  font-size: 14px !important;
  font-weight: 400 !important;
}
.top-col h3 {
  margin: 10px 0 0;
}
.top-col p {
  color: rgb(147, 148, 154);
  font-size: 14px;
  margin: 7px 0;
}
.clinic-field {
  width: 100%;
  margin-top: 30px;
  overflow-y: auto;
  height: 50vh;
}
.clinic-field h4 {
  background: #eaf1f7;
  color: #34344e;
  padding: 8px 0 8px 40px;
  font-size: 15px;
  font-weight: 700;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 20%;
}
.clinic-profile {
  width: 120px;
  height: 120px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 5px solid white;
  margin: 0px auto 20px;
}
.login-coll .MuiInputLabel-root {
  font-size: 14px;
  font-weight: 600 !important;
}
.clinic-details {
  width: 92%;
  padding-left: 40px;
  margin-bottom: 50px;
}
.account-details .form-group {
  display: flex;
}
.account-details {
  width: 94%;
  margin-bottom: 50px;
  display: flex;
}
.percent {
  position: absolute;
  right: 20px;
  font-weight: 600;
  top: 30px;
  color: rgb(147, 148, 154);
}
/*  */
@media all and (max-width: 1199px) {
  .container2 {
    width: 100%;
  }
}
@media all and (max-width: 767px) {
  .feature2 {
    padding-right: 0px;
  }
  .d-flex {
    display: flex;
    flex-direction: column;
  }
  .feature3 {
    padding-left: 0px;
  }
  .welcome-col {
    width: 100% !important;
  }
}

/* full calendar styles - slot booking */

.fc .fc-highlight {
  background: #111d4e !important;
}

.fc .fc-day-past .fc-daygrid-day-number {
  color: lightgray !important;
}

.fc .fc-daygrid-day-number {
  color: #464646 !important;
}

#calendar-section .fc-prev-button,
#calendar-section .fc-next-button {
  color: #111d4e !important;
}

.MuiToggleButton-root {
  background: #ccc;
}
.header-wrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
}
#available-times-div {
  height: 820px !important;
  overflow-y: auto;
}
#available-times-div h4 {
  font-size: 18px;
  font-weight: 600;
}
.time-btn {
  color: #fff !important;
  border: none !important;
  background: #111d4e !important;
}

.confirm-btn {
  background-color: #091c2e !important;
  cursor: pointer;
}

.time-btn:hover {
  border: none;
}

ul.Topnav {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

ul.Topnav li {
  margin-left: 15px;
}

ul.Topnav li a {
  color: #000;
}

ul.Topnav li a:hover {
  text-decoration: none;
}

.AboutContent {
  width: 100%;
  text-align: center;
  padding-bottom: 100px;
}

.AboutContent h2 {
  text-align: left;
}

.OurDoctor {
  width: 100%;
  padding: 0 0 70px;
}

.OurDoctor h3 {
  font-size: 28px;
  text-align: center;
  color: #263159;
  font-weight: normal;
  margin-bottom: 40px;
}

.service p {
  font-size: 16px;
  line-height: 24px;
}

.location-icon a {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #263159;
  justify-content: center;
}

.location-icon img {
  margin-right: 10px;
}

.doctorcontent {
  width: 100%;
  padding: 0 15px;
  text-align: center;
}

.doctorcontent h2 {
  font-size: 20px;
  color: #2f57a8;
  margin-bottom: 10px;
}

.doctorcontent p {
  font-size: 14px;
  color: #a4a4a4;
  margin-top: 0px;
}

.search {
  position: absolute;
  right: 10px;
  top: 82px;
}

.formcontrol {
  width: 100% !important;
}

.search-col {
  width: 50%;
  margin: 0px auto;
  position: relative;
}

.search-col h3 {
  font-size: 28px;
  color: #091c2e;
  margin-top: 30px;
}

.topseach {
  background: url(../src/images/7879.png) no-repeat top center;
  height: 700px;
  width: 100%;
  overflow-y: auto;
  background-size: contain;
}

.bodycontent {
  width: 1200px;
  margin: 0px auto;
}

.AboutContent {
  width: 100%;
  padding: 40px 0 90px;
}

ul.serviceList {
  list-style: none;
  margin: 0 0 80px;
  padding: 0px;
  text-align: center;
}

ul.serviceList li {
  padding-left: 20px;
  color: #263159;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  width: 20%;
  padding: 20px;
  position: relative;
  text-align: center;
  box-shadow: 2px 3px 11px 1px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 2px 3px 11px 1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 2px 3px 11px 1px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  margin: 15px 15px;
}

.doctorbox {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 2px 3px 11px 1px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 2px 3px 11px 1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 2px 3px 11px 1px rgba(0, 0, 0, 0.12);
}

.AboutContent h2 {
  font-size: 28px;
  color: #263159;
  font-weight: normal;
}

.doctorimg {
  width: 100%;
}

.AboutContent p {
  font-size: 18px;
  color: #263159;
  line-height: 24px;
  text-align: left;
}

/* button {
    background: #111d4e !important;
    color: #fff !important;
  } */

.service {
  width: 100%;
  padding-bottom: 50px;
}

.service h2 {
  font-size: 28px;
  color: #263159;
  text-align: center;
  font-weight: normal;
  margin-bottom: 30px;
}

.contact-info {
  width: 50%;
  margin: 0px auto;
}

.contact-info ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.contact-info ul li h5 {
  margin: 0px;
  font-size: 18px;
  color: #263159;
}

.contact-info ul li p {
  margin-top: 10px;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.8);
}

.contact-info ul li:nth-child(1) {
  width: 42%;
}

.contact-info ul li:nth-child(2) {
  width: 42%;
}

.contact-info ul li:nth-child(3) {
  width: 100%;
}

/* blog section */

.blog {
  width: 90%;
  padding: 90px 0;
  margin: 0px auto;
}

.blog h4 {
  text-align: center;
}

.featured-thumbnail img {
  width: 100%;
}
.featured-imagebox-blog a {
  font-size: 14px;
  font-weight: 500;
}
.box-shadow,
.featured-imagebox-blog {
  -webkit-box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.06);
  -moz-box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.06);
  box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.06);
}

.box-shadow2 {
  -webkit-box-shadow: 0 0 15px 0 rgba(32, 46, 60, 0.12);
  -moz-box-shadow: 0 0 15px 0 rgba(32, 46, 60, 0.12);
  box-shadow: 0 0 15px 0 rgba(32, 46, 60, 0.12);
}

.featured-imagebox-blog {
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
}

.featured-imagebox-blog .featured-thumbnail {
  position: relative;
  overflow: hidden;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.search-col {
  margin-bottom: 75px;
}

.featured-imagebox-blog .featured-content {
  padding: 45px 25px 25px 25px;
  position: relative;
}

.featured-imagebox-blog .ttm-box-post-date {
  left: 25px;
  top: -35px;
  border-radius: unset;
}

.ttm-box-post-date {
  position: absolute;
  left: 15px;
  top: 15px;
  border-radius: 5px;
  text-align: center;
  font-weight: 500;
  font-size: 17px;
  color: #fff;
  background: #2d4a8a;
}

.ttm-box-post-date .entry-date {
  height: 60px;
  width: 60px;
  display: block;
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.ttm-entry-date span {
  display: block;
}

.featured-title h5 {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
  font-weight: 600;
  margin-top: 0px;
  color: rgb(49, 61, 106);
}

.react-multi-carousel-dot-list {
  bottom: 30px !important;
}

.react-multi-carousel-dot--active button {
  background: #091c2e !important;
}

.react-multi-carousel-dot button {
  width: 15px !important;
  height: 15px !important;
  border: none !important;
  border-color: #fff;
  background: #fff;
}

.Topslider ul li {
  padding: 0px !important;
}

.Topslider .react-multiple-carousel__arrow--left {
  left: calc(2% + 1px) !important;
  display: none;
}

.Topslider .react-multiple-carousel__arrow--right {
  right: calc(2% + 1px) !important;
  display: none;
}

.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
}

.react-multiple-carousel__arrow {
  width: 43px !important;
  height: 43px !important;
  border-radius: 50% !important;
  background: rgb(49, 61, 106, 0.7) !important;
}

.blog h6 {
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.84);
  font-weight: normal;
  font-size: 18px;
  text-align: center;
}

.featured-imagebox-blog .post-meta {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  font-weight: 500;
}

.featured-imagebox-blog .post-meta .ttm-meta-line:not(:last-child) {
  padding-right: 23px;
}

.post-meta .ttm-meta-line {
  position: relative;
  color: rgb(49, 61, 106, 0.7) !important;
}

.featured-desc p {
  margin-bottom: 0;
  font-size: 14px;
  margin-bottom: 10px;
}

.ttm-btn.ttm-btn-size-sm.ttm-icon-btn-right {
  padding-right: 35px;
}
.home-video {
  width: 100%;
}
.home-video iframe {
  width: 100% !important;
}
.top-slider {
  margin-top: 0;
}
.scrolled {
  background-color: #fff !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  background-image: none !important;
}
.Logoimg,
.WhiteLog {
  height: 90px;
  margin-left: 25px;
}
.scrolled .WhiteLog {
  display: none;
}
.scrolled .Logoimg {
  display: block !important;
}
.scrolled .user-name {
  color: #000 !important;
}
.header-wrapper .Logoimg {
  display: none;
}
.header-wrapper {
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.header-wrapper .user-name {
  color: #fff;
  margin-right: 2rem;
}
.doctor-profile .header-wrapper {
  background-image: linear-gradient(to right top, #3e3be8, #3267f1);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
/* .top-header{ width: 100%; position: fixed; left: 0px; top: 0px; z-index: 9999; background-color: #fff;} */
/* blog details */
.top-header {
  position: absolute;
  top: 0;
  z-index: 99;
  left: 0;
  right: 0;
}
.post-meta {
  display: block;
  padding: 5px 0;
  margin: 10px 0;
}

.post-meta .ttm-meta-line {
  font-size: 13px;
  line-height: 15px;
  display: inline-block;
  padding-right: 25px;
  position: relative;
}

.post-meta .ttm-meta-line i {
  padding-right: 7px;
  font-size: 13px;
}

.Blogdetails h1 {
  margin-bottom: 0px;
  margin-top: 50px;
}

.Blogdetails p {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 15px;
  color: rgba(0, 0, 0, 0.8);
}

.widget_tinzer_posts_thumb {
  position: relative;
  overflow: hidden;
}

.widget .widget-title {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  margin-bottom: 25px;
  text-transform: capitalize;
  position: relative;
  font-size: 24px;
  font-weight: 500;
}

.widget .widget-title::before {
  content: '';
  position: absolute;
  background: #3b425d;
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}

.widget_tinzer_posts_thumb .item {
  overflow: hidden;
  margin-bottom: 20px;
}

.widget_tinzer_posts_thumb .item .thumb {
  float: left;
  height: 80px;
  overflow: hidden;
  position: relative;
  width: 80px;
  margin-right: 15px;
}

.widget_tinzer_posts_thumb .item .thumb .fullimage {
  width: 80px;
  height: 80px;
  display: inline-block;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}

.widget_tinzer_posts_thumb .item .info {
  overflow: hidden;
  margin-top: 0px;
}

.widget_tinzer_posts_thumb .item .info time {
  display: block;
  color: #7b7c93;
  text-transform: capitalize;
  font-size: 14px;
}

.widget_tinzer_posts_thumb .item .info .title {
  margin-bottom: 0;
  line-height: 1.4;
  font-size: 18px;
  font-weight: 600;
  margin-top: 5px;
}

.widget_tinzer_posts_thumb .item .info .title a {
  display: inline-block;
  color: #3b425d;
}

.forgotext {
  font-size: 16px;
  color: #000;
  margin-left: 10px !important;
  color: #9138aa !important;
  font-weight: bold;
  cursor: pointer;
}

.closemodal {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #9138aa;
  text-align: center;
  line-height: 35px;
  position: absolute;
  right: 20px;
  color: #fff;
  cursor: pointer;
}

.numberinput {
  width: 100%;
  height: 45px;
  background: #f1f1f1;
  padding: 0 15px;
  border-radius: 5px;
  border: none;
  line-height: 45px;
  color: #000;
}

.otpbtn {
  background: #212c50;
  padding: 10px 30px;
  border-radius: 40px;
  font-size: 16px;
  color: #fff;
  border: none;
}

.blogsidebar {
  width: 100%;
  margin-top: 50px;
}

.blogsidebar h3 {
  text-align: left;
}

.info p {
  font-size: 16px;
  margin-top: 0px;
  color: rgba(0, 0, 0, 0.8);
}

.Topslider {
  width: 100%;
}

ul.Leftnav {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
}

ul.Leftnav li {
  font-size: 18px;
  color: #858585;
  margin-bottom: 15px;
  font-weight: normal;
  padding-left: 0px;
}

ul.Leftnav li:hover {
  color: #121d47;
}

.LeftSide h2 {
  color: #121d47;
  margin-bottom: 30px;
  font-size: 23px;
}

ul.Leftnav li.active a {
  color: #121d47;
  font-weight: bold;
}

.cardrow {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.doctorcard {
  width: 37%;
  background: #fff;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0px 3px 7px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 3px 7px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 7px 3px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.doctorcard h5 {
  font-size: 24px;
  color: #121d46;
  font-weight: normal;
  margin-bottom: 0px;
  margin-top: 10px;
}

.doctorcard p {
  font-size: 20px;
  color: #7b7b7b;
  font-weight: normal;
}

.appointment {
  width: 75%;
  padding: 25px;
  background: #111c47;
  border-radius: 15px;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 30px;
  float: right;
}

.appointment p,
h5 {
  margin-bottom: 10px;
  color: #fff;
}

.appointment p {
  font-weight: normal;
  font-size: 20px;
  margin-top: 0px;
}

.appointment h5,
h6 {
  font-size: 20px;
  margin: 0 0 10px;
}

.checked-patient {
  width: 75%;
  padding: 25px;
  background: #8a339c;
  border-radius: 15px;
  text-align: left;
  float: right;
  color: #fff;
}

.checked-patient h2 {
  font-size: 50px;
  margin: 0px;
}

.Cardbox h3 {
  font-weight: normal;
  color: #101d46;
}

.inputcol {
  width: 100%;
  position: relative;
  float: left;
}

.add-address {
  position: absolute;
  right: 0px;
  top: 15px;
  color: #121d48;
  font-weight: normal;
}

.input {
  width: 100% !important;
}

.Address {
  width: 85%;
  position: relative;
  margin-left: 5px;
  background: #111c47;
  padding: 15px 15px;
  border-radius: 10px;
  font-weight: normal;
  color: #fff;
}

.add-servicve {
  position: relative;
  margin-right: 15px;
  background: #111c47;
  padding: 15px 15px;
  border-radius: 10px;
  font-weight: normal;
  color: #fff;
  float: left;
}

.close-icon {
  float: right;
  margin-left: 15px;
}

.add-btn {
  border: none;
  background: transparent;
  color: #121d48;
}

.update-btn {
  width: 140px;
  float: left;
  height: 45px;
  text-align: center;
  background: #111c47;
  line-height: 45px;
  color: #fff;
  border: none;
  border-radius: 50px;
  float: left;
  margin-top: 70px;
  font-size: 16px;
}

.inputcol h6 {
  font-weight: bold;
  margin-top: 40px;
  font-size: 18px;
  color: #121d48;
}

.inputcol p {
  font-size: 16px;
  color: #7b7b7b;
  font-weight: normal;
}

.uploadimage {
  width: 100%;
  margin-top: 70px;
  margin-bottom: 30px;
}

.uploadimage input,
.uploadbanner input {
  display: none;
}

.upload-btn {
  background-color: transparent !important;
  color: #121d48 !important;
  box-shadow: none !important;
}
.img-upload {
  position: absolute;
  bottom: 0px;
}
.img-upload .fa {
  font-size: 18px;
}
.uploadimagecol {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploadimagecol2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploadimagecol img {
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  width: 45%;
}

.uploadimagecol2 img {
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  width: 85%;
}

.MuiTableCell-alignRight {
  text-align: left !important;
}

.renew-btn {
  color: #3089b7;
  margin-right: 10px;
}

.cancel-btn {
  color: #cd4b4b;
}

.active-col {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.active-col h3 {
  margin-right: 40px;
}

.active-col h3 span {
  font-weight: bold;
  margin-left: 10px;
}

.modal-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.modal-btn .request-btn {
  margin-top: 0px !important;
}

.modal-btn a {
  margin-left: 20px;
}

.modal-btn a.cancel-btn {
  color: #7b7b7b;
}

.modal-close {
  float: right;
  font-size: 20px;
}

.request-btn {
  padding: 15px 40px;
  background: #111c47;
  border-radius: 40px;
  color: #fff;
  font-size: 16px;
  border: none;
  margin-top: 30px;
}

.bodycontent-col {
  display: flex;
  width: 90%;
  padding: 0 5%;
  margin-top: 25px;
  height: 100vh;
  flex-wrap: wrap;
}

.bodycontent-col h2 {
  margin-top: 0px;
}

.PricingTab {
  width: 360px !important;
  margin: 0px auto;
  background: transparent !important;
  box-shadow: none !important;
}

.PricingTab .MuiTab-textColorInherit {
  color: #333;
}

.PricingTab .MuiTab-textColorInherit.Mui-selected {
  background: transparent !important;
  color: #11224b !important;
}

.loginButton {
  color: #212121;
  margin-right: 30px;
  font-weight: 400;
}

.loginButton.active {
  color: #11224b !important;
}
/* billDesk modal */
.pay-amount {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.pay-amount h4 {
  font-size: 18px;
}
.pay-amount h4 span {
  font-size: 26px;
  color: #fe4a4b;
  font-weight: bold;
}
.servicetaken {
  display: flex;
}
.servicetaken .invoce-btn {
  background-color: #3f4c79;
  margin: 0 15px;
}
.select-btn {
  background-color: #3f4c79;
  height: 30px;
  color: #fff;
}
.servicetaken .paid-btn {
  background-color: #6dac66;
}
.modal-top h3 {
  margin-bottom: 0px;
}
.gst-price {
  display: flex;
  justify-content: space-between;
}
.service-list table td p {
  margin: 0 0 5px;
}
.recommand-form {
  display: flex;
}
.recommand-list {
  width: 30%;
  margin-right: 15px;
}
.recommand-counter {
  margin-right: 15px;
}
.recommand-submit {
  background-color: #3f4c79;
  padding: 0 15px;
  height: 55px;
}
.servicetaken .seravice-btn {
  width: 120px;
  height: 30px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 30px;
}
.MuiTablePagination-selectRoot {
  margin-right: 15px !important;
}
.MuiTablePagination-actions {
  margin-right: 0px !important;
  margin-left: 0px;
}
.page-content {
  width: 96%;
  margin: 0px auto;
}
.box-col {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.1);
}
.box-col2 {
  background: #fff;
  min-height: 150px;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.1);
}
.wiget-content h4 {
  font-size: 18px;
  color: #0f1b4c;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}
.wiget-content h3 {
  font-size: 24px;
  font-weight: 500;
  color: #9635b1;
  margin-bottom: 0px;
  height: 35px;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}
.table-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.table-top h4 {
  flex: 0 0 50%;
  margin-bottom: 0px;
  color: #111d4e;
  font-size: 16px;
  font-weight: 600;
}
.col-bg {
  width: 70%;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  background: url(../src/images/clinic-bg.jpg) no-repeat center top;
  background-size: cover;
  z-index: -9;
}
.col-left {
  width: 30%;
  left: 0px;
  top: 0px;
  bottom: 0px;
  background: linear-gradient(45deg, #680fad, #063bc4);
  position: fixed;
  z-index: -9;
}
.login-coll {
  width: 280px;
  background-color: #fff;
  padding: 35px;
  border-radius: 10px;
}
.Logincol h3,
.Logincol p {
  text-align: center;
}
.Logincol h3 {
  color: #0d1843;
  margin-bottom: 5px;
  font-size: 1.4rem;
  font-weight: 600;
}
.Logincol p {
  margin: 0px;
  color: #78797b;
  font-size: 14px;
}
.login-box {
  height: 100vh !important;
  padding-left: 15%;
}
.card-box {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  padding: 20px;
}
.subscribe-modal {
  width: 100%;
  background: #fff;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-col {
  width: 450px;
  padding: 30px;
  border-radius: 5px;
  border: 1px rgba(0, 0, 0, 0.1) solid;
}
.subscribe-modal-top {
  text-align: center;
}
.subscribe-modal-top img {
  width: 80px;
}
.subscribe-modal-top h4 {
  font-size: 20px;
  font-weight: 600;
  color: #3d404a;
}
.modal-col .MuiListItem-button:hover {
  background: none !important;
}
.modal-col p {
  font-size: 14px;
  font-weight: 600;
  color: #3d404a;
  margin-left: 15px;
}
.modal-col .MuiAccordion-root,
.modal-col .Mui-expanded {
  box-shadow: none !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.modal-col .MuiAccordion-root:before {
  height: 0px !important;
}
.sub-item {
  padding: 0px;
  background: none;
  font-family: 'Poppins', sans-serif !important;
  height: 35px;
  text-align: left;
  font-size: 13px;
  color: #3d404a;
  padding-left: 20px;
  position: relative;
  color: #0d1843;
}
.sub-item:before {
  width: 6px;
  height: 6px;
  left: 0px;
  top: 13px;
  position: absolute;
  content: '';
  border-radius: 50%;
  background: #888ea6;
}
.modal-col h5 {
  font-size: 14px;
  color: #4e5570;
  background: #eaf1f7;
  border-radius: 5px;
  padding: 7px 15px;
}
nav ul li {
  width: 100%;
  padding: 0px;
  border-bottom: 1px rgba(255, 255, 255, 0.1) solid;
  border-radius: 0px;
}
nav ul li a {
  padding: 10px 15px;
  color: #fff;
}
nav ul li .MuiSvgIcon-root {
  fill: #fff !important;
}
.MuiPagination-ul li {
  width: auto;
}
.MuiPagination-ul li .MuiSvgIcon-root {
  fill: #333 !important;
}
#calendar .fc-scroller-liquid-absolute {
  position: relative !important;
}
@media all and (min-width: 768px) and (max-width: 1199px) {
  /* .owl-stage .ui-card:nth-child(1) {
    margin-right: -2rem;
    margin-left: 2rem;
  } */
  .content-col {
    width: 100%;
    margin: 0px auto;
    background-color: #fff;
  }
  .container2 {
    width: 100%;
  }
  .ui-card {
    width: 33%;
  }
  .Topheader {
    width: 95%;
    padding: 0 15px;
  }
  .bodycontent {
    width: 95%;
    padding: 0 15px;
  }
  /* .owl-stage .ui-card:nth-child(3) {
    margin-right: 2rem;
    margin-left: -2rem;
  } */
  .ui-card h4 {
    font-size: 20px;
  }
  .price .price {
    font-size: 20px;
  }
  .feature2 {
    padding-right: 35px;
  }
  .feature3 {
    padding-left: 35px;
  }
  .left-box1 {
    padding-left: 10px;
  }
  .right-box3 {
    padding-right: 10px;
  }
}

@media all and (min-width: 320px) and (max-width: 1024px) {
  .LeftSide {
    display: none;
  }
  .content-col {
    width: 100%;
    margin: 0px auto;
  }
  .Cardbox {
    max-width: 75%;
    flex-basis: 75%;
  }
  .loginButton {
    margin-right: 1.5rem;
    margin-bottom: 0px;
  }
  .container2 {
    width: 100%;
  }
}
@media all and (min-width: 768px) and (max-width: 991px) {
  .payment-plan ul li {
    width: 47%;
    padding: 0 10px;
  }
}
@media all and (min-width: 320px) and (max-width: 850px) {
  .update-profile {
    width: 100%;
  }
  #calendar .fc-view-harness {
    height: 400px !important;
  }
  .update-profile-col {
    width: 100%;
  }
  .update-profile-col:nth-child(1) {
    padding-right: 0px;
  }
  .update-profile-col:nth-child(2) {
    padding-left: 0px;
  }
  .update-profile-col:nth-child(3) {
    padding-right: 0px;
  }
  .update-profile-col:nth-child(4) {
    padding-left: 0px;
  }
  .update-profile-col:nth-child(5) {
    padding-right: 0px;
  }
  .update-profile-col:nth-child(6) {
    padding-left: 0px;
  }
  .update-profile-col:nth-child(7) {
    padding-right: 0px;
  }
  .update-profile-col:nth-child(8) {
    padding-left: 0px;
  }
  .update-profile-col:nth-child(9) {
    padding-right: 0px;
  }
  .update-profile-col:nth-child(10) {
    padding-left: 0px;
  }
  .update-profile-col:nth-child(11) {
    padding-right: 0px;
  }
  .update-profile-col:nth-child(12) {
    padding-left: 0px;
  }
  .update-profile-col:nth-child(13) {
    padding-right: 0px;
  }
  .update-profile-col:nth-child(14) {
    padding-left: 0px;
  }
  .update-profile .MuiTextField-root {
    width: 100% !important;
  }
  #available-times-div {
    height: 300px !important;
    overflow-y: auto;
  }
  .carousel-row p {
    font-size: 14px;
  }
  .carousel-row h3 {
    font-size: 18px;
  }
  .container2 {
    width: 100%;
  }
  .container2 .MuiStepContent-root {
    padding: 0px;
    margin: 0px;
    border: 0px;
  }
  .payment-plan ul li {
    width: 100%;
    padding: 0px;
  }
  .res-mb-2 {
    margin-bottom: 15px !important;
  }
  .payment-frequency,
  .plan-amount {
    margin-bottom: 0px;
  }
  .responsive-table {
    overflow-x: auto;
  }
  .plan-details .table {
    width: 700px;
  }
  .plan-details h4 {
    font-size: 14px;
  }
  .total-amount,
  .plan-details .table td,
  th {
    font-size: 14px;
  }
  .responsive-payment-frequency {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@media all and (min-width: 320px) and (max-width: 767px) {
  /* .MuiTabs-flexContainer {
    overflow-x: auto !important;
  } */
  .login-box {
    padding-left: 0px;
    align-items: center !important;
  }
  .col-left {
    width: 100%;
    bottom: initial;
    height: 90px;
  }
  .col-bg {
    display: none;
  }
  .back {
    left: 15px;
    top: 0px;
  }
  .top-col p {
    text-align: center;
  }
  .top-col {
    padding-top: 50px;
  }
  .clinic-top {
    padding-left: 20px;
    padding-right: 20px;
  }
  .clinic-details {
    padding-left: 15px;
  }
  .clinic-field h4 {
    width: 70%;
  }
  .main-content-coll {
    padding: 0 20px 25px;
    width: 88%;
  }
  .main-content-coll p {
    font-size: 12px !important;
  }
  .btn {
    font-size: 12px;
  }
  .ulockd-service ul li {
    width: 96%;
  }
  .service-strip h3 {
    font-size: 1.5rem;
  }
  .service-strip h4 {
    font-size: 1rem;
  }
  .content-col h2 {
    font-size: 1.5rem;
  }
  .content-col {
    width: 100%;
    margin: 0px auto;
  }
  .MuiDialog-paper {
    margin: 10px !important;
    width: 96%;
  }
  .MuiDialogContent-root {
    padding: 8px 20px !important;
  }
  .payment-col h4 span {
    padding-left: 0;
    width: 100%;
    display: block;
    margin-top: 15px;
  }
  .payment-col h2 {
    font-size: 28px;
  }
  .payment-col h3 {
    font-size: 16px;
  }
  .vital-col {
    padding: 0 10px 10px 10px;
  }
  .MuiTable-root {
    width: 750px !important;
  }
  .loginButton {
    margin-right: 1.5rem;
    margin-bottom: 15px;
  }
  .owl-stage .ui-card:nth-child(3) {
    margin-left: 0;
  }
  .update-profile {
    width: 100%;
  }
  .update-profile-col {
    width: 100%;
  }
  .update-profile-col:nth-child(1) {
    padding-right: 0px;
  }
  .update-profile-col:nth-child(2) {
    padding-left: 0px;
  }
  .update-profile-col:nth-child(3) {
    padding-right: 0px;
  }
  .update-profile-col:nth-child(4) {
    padding-left: 0px;
  }
  .update-profile-col:nth-child(5) {
    padding-right: 0px;
  }
  .update-profile-col:nth-child(6) {
    padding-left: 0px;
  }
  .update-profile-col:nth-child(7) {
    padding-right: 0px;
  }
  .update-profile-col:nth-child(8) {
    padding-left: 0px;
  }
  .update-profile-col:nth-child(9) {
    padding-right: 0px;
  }
  .update-profile-col:nth-child(10) {
    padding-left: 0px;
  }
  .Datatable .MuiTable-root {
    width: 700px !important;
    min-width: 700px !important;
    overflow: scroll;
  }
  .Topcol {
    flex-wrap: wrap;
  }
  .Addtext a {
    font-size: 0.9rem;
  }
  .Logincol h3 {
    font-size: 24px;
  }
  .pay-box {
    padding: 20px 15px;
  }
  .pay-top h4 {
    font-size: 14px;
  }
  .scrollButtonsDesktop svg path {
    fill: #000;
  }
  .Subheading span {
    font-size: 22px;
  }
  .pay-box {
    width: 100%;
  }
  .Subheading {
    font-size: 18px;
    margin-bottom: 15px !important;
  }
  .PricingTab {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Cardprofile {
    width: 100%;
    margin: 0px auto;
    position: relative;
  }
  .userinfo p {
    color: #fff !important;
  }
  .search-col {
    width: 90%;
    margin-bottom: 25px;
  }
  .formcontrol {
    width: 96% !important;
  }
  .Topslider img {
    height: auto;
  }
  .Topheader {
    width: 95%;
    padding: 5px 0;
  }
  .bodycontent {
    width: 90%;
    padding: 0 15px;
  }
  .MuiTab-root {
    min-width: 120px !important;
  }
  /* .owl-stage .ui-card:nth-child(1) {
    margin-right: -1.5rem;
    margin-left: 1.5rem;
  } */
  .owl-stage {
    flex-direction: column;
  }
  .ui-card {
    width: 100%;
  }
  /* .owl-stage .ui-card:nth-child(2) {
    margin-top: 0px;
    margin-left: 1.5rem;
  }
  .owl-stage .ui-card:nth-child(3) {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  } */
  .profile-info h3 {
    font-size: 16px;
    height: 30px;
    margin-top: 20px;
  }
  .tele-time-btn,
  .confirm-btn,
  .disabledTimeSlot {
    font-size: 12px !important;
  }
  .profile-responsiveimg {
    display: block;
  }
  .header-wrapper .user-name {
    color: #000;
  }
  .topheader-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media all and (min-width: 320px) and (max-width: 600px) {
  .profile-col {
    width: 94%;
  }
  .profile-top::before {
    height: 160px;
  }
  .top-logo img {
    height: 85px;
  }
  .profile-info h3 {
    font-size: 16px;
    height: 30px;
  }
  .profile-info p {
    font-size: 16px;
  }
  .profile-img {
    width: 115px;
    height: 115px;
    margin-top: -15px;
    flex: 0 0 115px;
  }
  .contact-col {
    width: 100%;
    font-size: 14px;
  }
  .header-wrapper .user-name {
    color: #000;
    margin-right: 0px;
  }
}

@media all and (min-width: 320px) and (max-width: 480px) {
  .rbc-row-segment {
    padding: 0 1px 1px 1px;
    width: 45%;
    max-width: 45% !important;
    flex-basis: 45% !important;
  }
  .total-amount {
    height: 55px;
  }
  .profile-col {
    width: 94%;
  }
  .profile-top::before {
    height: 140px;
  }
  .profile-img {
    width: 80px;
    height: 80px;
    margin-top: 10px;
    flex: 0 0 80px;
  }
  .top-logo img {
    height: 62px;
  }
}

.fc .fc-highlight {
  background: #99cbff !important;
}

.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
  font-family: 'Poppins', sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  color: #464646;
}

#calendar-section .fc-toolbar-title {
  font-size: 1rem;
  font-weight: 500;
  background-color: #efefef;
  padding: 10px 20px;
  color: #111d4e;
}

.fc .fc-daygrid-day-frame {
  cursor: pointer;
}

.MuiAutocomplete-endAdornment {
  top: auto !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  background-color: #0d46c1 !important;
  color: #fff !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 25px;
  padding-right: 25px;
  font-family: poppins;
}
.MuiTab-textColorPrimary {
  font-family: poppins !important;
  color: #000 !important;
}
.__floater__open {
  z-index: 9999999999 !important;
}

.e-lib {
  margin-bottom: 0 !important;
}

.MuiAutocomplete-input {
  border: none !important;
}

.MuiInputBase-input {
  border: none !important;
}
@media screen and (min-device-width: 320px) and (max-device-width: 850px) and (orientation: portrait) {
  .mobile-orientation {
    display: none !important;
  }
  .Logoimg,
  .WhiteLog {
    height: 60px;
    margin-left: 25px;
  }
  .WhiteLog {
    display: none;
  }
  .Logoimg {
    display: block !important;
  }
  .doctor-profile .header-wrapper {
    background-image: none;
    background-color: #fff;
  }
  .top-slider {
    margin-top: 85px;
  }
  .MuiDrawer-paper a {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 850px) and (orientation: landscape) {
  .mobile-orientation {
    display: block !important;
  }
}

.swal2-container {
  z-index: 999999 !important;
}
